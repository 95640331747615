import axios from '@axios'
import endpoints from '@enums/endpoints'

export default {
  /**
   * Fetch website configs values
   * @param {Object} params
   * @returns {Promise<AxiosResponse<any>>}
   */
  getWebsiteConfigs (params) {
    return axios.get(endpoints.WEBSITE_CONFIGS, { params })
  },
  /**
   * Fetch website config values
   * @param {string} websiteConfigIRI
   * @returns {Promise<AxiosResponse<any>>}
   */
  getWebsiteConfig (websiteConfigIRI) {
    return axios.get(websiteConfigIRI)
  },
  /**
   * Update website config
   * @param {string} websiteConfigIRI
   * @param {Object} data
   * @returns {Promise<AxiosResponse<any>>}
   */
  updateWebsiteConfig (websiteConfigIRI, data) {
    return axios.patch(websiteConfigIRI, data)
  },
  /**
   * Lock / Unlock website config (use fetch for keepalive option)
   * @param {*} websiteConfigIRI
   * @param {boolean} lock
   * @returns {Promise<AxiosResponse<any>>}
   */
  lockWebsiteConfig (websiteConfigIRI, lock = true) {
    const lockEndpoint = endpoints.WEBSITE_CONFIGS_LOCK.replace('{{websiteConfigIRI}}', websiteConfigIRI)
    return fetch(axios.defaults.baseURL + lockEndpoint, {
      method: 'PATCH',
      headers: {
        ...axios.defaults.headers.common,
        'content-type': 'application/merge-patch+json'
      },
      mode: 'cors',
      body: JSON.stringify({ lock }),
      keepalive: true
    })
  },
  /**
   * Publish website config
   * @param {string} itemIRI
   * @param {Object} data
   * @returns {Promise<AxiosResponse<any>>}
   */
  publishWebsiteConfig ({ itemIRI, data }) {
    return axios.patch(endpoints.WEBSITE_CONFIGS_PUBLISH.replace('{{websiteConfigIRI}}', itemIRI), data)
  },
  /**
   * Delete website config
   * @param {string} websiteConfigIRI
   * @returns {Promise<AxiosResponse<any>>}
   */
  deleteWebsiteConfig (websiteConfigIRI) {
    return axios.delete(websiteConfigIRI)
  },
  /**
   * Duplicate website config
   * @param {string} websiteConfigIRI
   * @returns {Promise<AxiosResponse<any>>}
   */
  duplicateWebsiteConfig (websiteConfigIRI) {
    return axios.post(endpoints.DUPLICATED_WEBSITE_CONFIGS, { websiteConfig: websiteConfigIRI })
  },
  /**
   * Get website config ads.txt values
   * @param {string} websiteConfigIRI
   * @param {Object} params
   * @returns {Promise<AxiosResponse<any>>}
   */
  getWebsiteConfigAdsTxt (websiteConfigIRI, params) {
    return axios.get(endpoints.WEBSITE_CONFIGS_ADS_TXT.replace('{{websiteConfigIRI}}', websiteConfigIRI), { params })
  },
  /**
   * Update website config ads.txt
   * @param {string} websiteConfigIRI
   * @param {Object} data
   * @returns {Promise<AxiosResponse<any>>}
   */
  updateWebsiteConfigAdsTxt (websiteConfigIRI, data) {
    return axios.patch(endpoints.WEBSITE_CONFIGS_ADS_TXT.replace('{{websiteConfigIRI}}', websiteConfigIRI), {
      adstxt: data
    })
  }
}
