export default Object.freeze({
  ACTIVE: 'active',
  ADS_TXT: 'adstxt',
  ALLOWED_USERS: 'allowed_users',
  BIDDERS: 'bidders',
  CONTRACT_PARAMETERS: 'contract_parameters',
  CONTRACT_SPECIFIC_CONDITION: 'contract_specific_condition',
  CONTRACT_STATUS: 'contract_status',
  DIFF: 'diff',
  EMPTY: 'empty',
  FEES: 'fees',
  LOCAL_REGISTRATION_ID: 'local_registration_id',
  NOTE: 'note',
  ORGANIZATION: 'organization',
  PLAINPASSWORD: 'plainPassword',
  PRESIZED_DIVS: 'presized_divs',
  PUBLISH: 'publish',
  ROLE: 'role',
  SELLERS_JSON_ORGANIZATION: 'sellers_json_organization',
  SERVICE_STATUS: 'service_status',
  TAGGING: 'tagging',
  UNLOCK: 'unlock',
  USER_GROUP: 'user_group',
  UUID: 'uuid',
  VALIDATED: 'validated',
  VIOUSLY_AFFILIATE_ID: 'viously_affiliate_id'
})
