import views from '@enums/views'
// import aclActions from '@enums/aclActions'
// import aclObjects from '@enums/aclObjects'

const OnboardingsList = () =>
  import(/* webpackChunkName: "onboardings-list" */ '@components/tables/OnboardingsTable.vue')

export default [
  {
    path: '/auto-onboarding',
    name: views.ONBOARDINGS,
    component: OnboardingsList,
    meta: {
      appBreadcrumb: [
        {
          text: 'onboardings',
          active: true
        }
      ]
      // action: aclActions.LIST,
      // object: aclObjects.ONBOARDING
    }
  }
]
