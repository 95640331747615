// Key label matching with statusValues i18n locales
export default Object.freeze({
  APPROVED: {
    value: 'approved',
    label: 'statusValues.approved',
    variant: 'success'
  },
  PENDING: {
    value: 'pending',
    label: 'statusValues.pending',
    variant: 'warning'
  },
  REFUSED: {
    value: 'refused',
    label: 'statusValues.refused',
    variant: 'danger'
  },
  SUSPENDED: {
    value: 'suspended',
    label: 'statusValues.suspended',
    variant: 'secondary'
  }
})
