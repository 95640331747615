import {
  stateTable, gettersTable, mutationsTable, actionsTable
} from '@store/modules/table'
import table from '@enums/table'
import onboardingStatus from '@enums/onboardingStatus'
import websiteProducts from '@enums/websiteProducts'

export const state = {
  service: {
    name: 'websitesService',
    getItemsMethod: 'getWebsites',
    getItemMethod: 'getWebsite',
    patchItemMethod: 'updateWebsite'
  },
  ...stateTable,
  tableFiltersState: {
    [table.ORDER]: {
      domain: table.SORT_ASC
    }
  },
  tableColumnFilters: {
    onboardingStatus: onboardingStatus.PENDING?.value,
    websiteProduct: [websiteProducts.WORDPRESS_PLUGIN]
  }
}

export const getters = {
  ...gettersTable
}

export const mutations = {
  ...mutationsTable
}

export const actions = {
  ...actionsTable
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
